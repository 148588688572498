






  import { Component, Vue } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'
  import { Account } from '@one/types'

  const account = namespace('account')

  @Component({
    components: {
      OneManagerInfo: () => import('~/components/molecules/navigations/OneManagerInfo.vue'),
    },
  })
  export default class OneManagerInfoComponent extends Vue {
    @account.Getter getClient: any

    get manager(): Account.App.Responses.AccountManager | null {
      return this.getClient && this.getClient.accountManager
    }

    get hasManager(): boolean {
      return !!(this.getClient && this.getClient.accountManager)
    }
  }
